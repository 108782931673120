<template>
  <div id="custom-stepper">
    <v-parallax height="370" src="../assets/coliseo_grande.jpeg" class="hidden-md-and-down">
      <div class="text-center text-uppercase text-h3">Reserva</div>
    </v-parallax>
    <v-container>
      <div class="py-3">
        <a href="#"
          class="text-decoration-none" @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon>Volver atras</a>
      </div>
      <v-stepper
          class="mt-12"
          non-linear
          flat
          elevation="0"
          height="140"


      >
        <v-stepper-header>
          <v-stepper-step
              :complete="step >= 1"
              complete-icon="mdi-calendar-month"
              step="1"
          >
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
              :complete="step >= 2"
              edit-icon="mdi-account-group"
              complete-icon="mdi-account-group"
              step="2"
          >

          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
              :complete="step >= 3"
              edit-icon="mdi-credit-card-outline"
              complete-icon="mdi-credit-card-outline"
              step="3"
          >
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
              :complete="step >= 4"
              edit-icon="mdi-check"
              complete-icon="mdi-check"
              step="4"
          >
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "StepperCustom",
  props:['step','src']
}
</script>

<style lang="scss">
#custom-stepper{
  .v-stepper__step__step{
    height: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
    .v-icon{
      .v-icon{
        font-size: 2rem !important;
      }
    }
  }
  .v-stepper__header{
    box-shadow: none !important;
  }
}

</style>