import axios from "axios";
import store from "../plugins/store"

const API_BASE_URL = store.state.api_backend;
const ANPHI = 1;
const RESTAURANT = 1;

const User = {
    login: (params) => axios.post(API_BASE_URL+'api/login', params)
        .then( result => store.commit('setUser',result.data)),
    get: () => axios.get(API_BASE_URL+'api/'+ANPHI+'/amphievent'),
}
const AmphiEvents = (authType =  "Bearer" ,authToken = "1|jvUAY0EqQJbGtAAHEqzpdZmf59EfidrJINVr87nA") => {
    if(authType){
        axios.defaults.headers.common = {
            "Authorization": `${authType} ${authToken}`,
        };
    }
    return{
        getEvents: (params = {}) =>  axios.get(API_BASE_URL+`api/${ANPHI}/amphievent`,{
            params: params
        }),
        getEvent: (id) => axios.get(API_BASE_URL+ `api/amphievent/${id}`),
        getTables: (scheduleId, params={}) => axios.get(API_BASE_URL+ `api/${scheduleId}/available-tables`,
            {params:params}),
        getEventSchedule: (id) => axios.get(API_BASE_URL + `api/eventschedule/${id}`),
        getCategory: () => axios.get(API_BASE_URL+ 'api/eventcategory'),
        getEventOpen: () => axios.get(API_BASE_URL + 'api/eventschedule/now'),
        getAmphitheater: () => axios.get( API_BASE_URL + 'api/amphitable'),
        updateTable: (id,capacity) => axios.post( API_BASE_URL + `api/amphitable/${id}`,
            {'capacity': capacity, _method: 'PUT'}),
    }
}
const Reservation = (authType =  "Bearer" ,authToken = "1|jvUAY0EqQJbGtAAHEqzpdZmf59EfidrJINVr87nA") => {
    axios.defaults.headers.common = {
        "Authorization": `${authType} ${authToken}`,
    };
    return{
        all: ()=> axios.get(API_BASE_URL+ `api/amphireservation`),
        get: (id)=> axios.get(API_BASE_URL+ `api/amphireservation/${id}`),
        create: (params) => axios.post(API_BASE_URL + `api/amphireservation`, params),
        getTicket: (code)=> axios.get(API_BASE_URL+ `api/amphiticket/${code}`),
        pkpass: (idTicket) => axios.get(API_BASE_URL + `api/amphiticket/${idTicket}/download`,
            {responseType: 'blob'})
    }
}
const Category = (authType =  "Bearer" ,authToken = "1|jvUAY0EqQJbGtAAHEqzpdZmf59EfidrJINVr87nA") => {
    axios.defaults.headers.common = {
        "Authorization": `${authType} ${authToken}`,
    };
    return{
        get: () => axios.get(API_BASE_URL+ 'api/eventcategory')
    }

}
const Restaurant = (authType =  "Bearer" ,authToken = "1|jvUAY0EqQJbGtAAHEqzpdZmf59EfidrJINVr87nA") => {
    let partialUrl= 'https://stage.lamarinaresorts.com/'
    axios.defaults.headers.common = {
        "Authorization": `${authType} ${authToken}`,
        'Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
    };
    return{
        getItems: () => axios.get(partialUrl + `api/${RESTAURANT}/restaurantarticle`),
        getCategory: () => axios.get(partialUrl + `api/${RESTAURANT}/articlecategory`),
        getOrder: () => axios.get(API_BASE_URL + `api/restaurantorder/${store.state.order.id}`),
        getDrinks:(item) => axios.get(API_BASE_URL + `api/restaurantorder/${store.state.order.id}/drinks/${item}`),
        createOrder: (params) => axios.post(API_BASE_URL + `api/restaurantorder`, params),
        addArticle: (id, params) => axios.post(API_BASE_URL + `api/restaurantorder/${id}`, params),
        updateArticle: (id, quantity) => axios.post(API_BASE_URL + `api/restaurantorder/${store.state.order.id}`,
            {id: id, quantity: quantity, _method: 'PUT'}),
        PayOrder: (token, totem= false) => {
            //in case of totem the token is the terminal!
            if(totem){
                return axios.post(API_BASE_URL + `api/restaurantorder/${store.state.order.id}/paytotem`,
                    {'terminal': token})
            }else{
                return axios.post(API_BASE_URL + `api/order/${store.state.order.id}/pay`,
                    {'paytpvToken': token})
            }

        },
        checkIfPayed:(ruid, terminal)=>{
            axios.post(API_BASE_URL + `api/restaurantorder/${store.state.order.id}/paytotem`,
                {
                    'terminal': terminal,
                    'ruid': ruid
                })
        },
        updatePhone: (phone) => axios.post(API_BASE_URL + `/api/restaurantorder/${store.state.order.id}/pay`,
            {'phone_number': phone, _method: 'PUT'}),
    }
}

export {
    User,
    AmphiEvents,
    Reservation,
    Category,
    Restaurant,
}