<template>
  <div>
    <stepper-custom :step="1"></stepper-custom>
    <!--  <stepper-custom :src="event_data.image" :step="1"></stepper-custom>-->
    <v-container v-if="event_data">

      <v-row>
        <v-col
            cols="12"
            sm="12"
            md="6"
            lg="8"
            class="align-lg-stretch"
        >
          <v-card color="#F3F3F3" elevation="0" tile height="100%">
            <v-row class="ma-0">
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                  class="pa-0"
              >
                <!--              <v-img-->
                <!--                  class="align-lg-stretch"-->
                <!--                  height="325"-->
                <!--                  :src="event_data.image"-->
                <!--              ></v-img>-->
                <v-img
                    class="align-lg-stretch"
                    max-height="370"
                    :src="event_data.image"
                    :lazy-src="event_data.image"
                ></v-img>
              </v-col>

              <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="8"
              >
                <v-card-title class="text-h4 primary--text">{{event_data.name}}</v-card-title>
                <v-card-text class="text-p">{{event_data.description}}</v-card-text>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="6"
            lg="4"
        >
          <v-card color="#F3F3F3" elevation="0" tile>
            <v-container>
              <v-card-text class="text-uppercase text-p primary--text pa-0 mb-1">Fechas</v-card-text>
              <v-select
                  :items="dates"
                  v-model="dateSelected"
                  solo
                  flat
              ></v-select>
              <v-card-text class="text-uppercase text-p primary--text pa-0 mb-1">Horario</v-card-text>
              <v-select
                  :disabled="!dateSelected"
                  :items="hours[dateSelected]"
                  v-model="hourSelected"
                  solo
                  flat
              ></v-select>
              <v-card-text class="text-uppercase text-p primary--text pa-0 mb-1">PERSONAS A ASISTIR</v-card-text>
              <v-select
                  :items="person"
                  :disabled="!hourSelected"
                  v-model="personSelected"
                  solo
                  flat
              ></v-select>
              <v-btn
                  :disabled="!dateSelected || !hourSelected || !personSelected"
                  color="primary"
                  elevation="0"
                  tile
                  block
                  @click="goToSelectTable()"
              >
                <v-icon
                    left
                    color="white"
                >
                  mdi-cart
                </v-icon> Ir a selección de asientos
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import {AmphiEvents} from "@/services/api";
import StepperCustom from "@/components/StepperCustom";

export default {
  name: "AboutEvent",
  components: {StepperCustom},
  data(){
    return{
      event_data: null,
      dateSelected: null,
      hourSelected: null,
      personSelected: null,
    }
  },
  mounted() {
    this.getEvent()
  },
  metaInfo() {
    return {
      title: `${this.event_data? this.event_data.name : 'Evento'} | La Marina Anfiteatro`,
      meta: [
        {name: 'description', content: this.event_data ? this.event_data.description: ''},
        { property: 'og:title', content: `Entradas para ${this.event_data ? this.event_data.name : 'Evento'}`},
        { property: 'og:site_name', content: 'La Marina Anfiteatro'},
        {property: 'og:type', content: 'website'},
      ]
    }
  },
  methods:{
    goToSelectTable(){
      this.$router.push({
        name: 'SelectTable',
        params: { id: this.hourSelected, people: this.personSelected}
      })
    },
    getEvent(){
      AmphiEvents(null).getEvent(this.eventId).then(
          response => {
            this.event_data = response.data.data
          }
      )
          .finally( ()=>
              this.$store.commit('setLoading', false)
          )
    },
  },
  computed: {
    eventId() {
      return this.$route.params.id || this.$route.query.id
    },
    dates(){
      let days = []
      this.event_data.schedules.forEach((schedule) => {
        let date = this.$moment(schedule.schedule).locale('es').format('dddd, D [de] MMMM [de] YYYY')
        if (days.indexOf(date) === -1)
          days.push(date)
      })
      return days
    },
    hours(){
      let hoursByday = {}
      this.dates.forEach(elem => {
        let hours = Array();
        this.event_data.schedules.forEach((schedule) => {
          console.log(schedule)
          let date = this.$moment(schedule.schedule).locale('es')
          if (elem === date.format('dddd, D [de] MMMM [de] YYYY')) {
            hours.push({value: schedule.id, text: date.format('HH[:]mm')});
          }
        })
        hoursByday[elem] = hours
      })
      return hoursByday
    },
    person(){
      let max_person = 'schedules' in this.event_data &&  this.hourSelected ?  this.event_data.schedules.find(elem => elem.id = this.hourSelected).maxCapacity : 0
      return max_person > 0 ? Array.from(Array(max_person), (elem,index) => index+1) : []

    }
  }
}
</script>

<style scoped>

</style>
